<template>
  <div class="join-to-seminar-view">
    <PageHeader
        class="mb-4"
        :backButtonTo="`/home`"
    >
      Присоединиться к семинару
    </PageHeader>

    <b-overlay
        :show="isLoading"
        rounded="sm"
        variant="white"
        opacity="0.85"
        spinner-variant="primary"
    >

      <!-- Информация о семинаре -->
      <b-card
          v-if="seminar"
          class="mb-3"
          :img-src="seminar.image"
          img-start
      >
        <b-card-text>
          <p><strong>Название:</strong> {{ seminar.title }}</p>

          <p>
            <strong>Описание:</strong><br/>
            {{ seminar.description }}
          </p>

          <p>
            <strong>Начало: </strong><br/>
            {{ seminar.startDt }}
          </p>
        </b-card-text>
      </b-card>
      <!-- /Информация о семинаре -->

      <!-- Вы уже присоединились ранее! -->
      <b-alert
          v-if="checkJoiningToSeminarStatus"
          show
          variant="success"
      >
        Вы уже присоединились ранее!
      </b-alert>
      <!-- /Вы уже присоединились ранее! -->

      <!-- Ошибка сервера при получение начальных данных -->
      <b-alert
          show
          v-if="initPageServerErrorMessage"
          variant="danger"
      >
        {{ initPageServerErrorMessage }}
      </b-alert>
      <!-- /Ошибка сервера при получение начальных данных -->

      <div
          v-if="!initPageServerErrorMessage && isSent"
      >
        <div class="py-4 text-center">
          <b-icon
              icon="check-circle-fill"
              font-scale="3"
              variant="success"
          ></b-icon>
        </div>
      </div>

      <div
          v-if="!initPageServerErrorMessage && !isSent && !checkJoiningToSeminarStatus"
      >

        <b-form @submit="onSubmitForm">
          <!-- Поле Имя -->
          <p class="mb-2">
            Имя <span class="text-danger font-weight-bold">*</span>
          </p>
          <b-form-group
              label-for="input-phone"
              :invalid-feedback="form.userName.invalidFeedback"
              :state="form.userName.state"
          >
            <b-form-input
                id="input-phone"
                v-model="form.userName.value"
                :state="form.userName.state"
                trim
                placeholder="Имя"
                :disabled="true"
            />
          </b-form-group>
          <!-- /Поле Имя -->

          <!-- Поле Телефон -->
          <p class="mb-2">
            Телефон <span class="text-danger font-weight-bold">*</span>
          </p>
          <b-form-group
              label-for="input-phone"
              :invalid-feedback="form.phone.invalidFeedback"
              :state="form.phone.state"
          >
            <b-form-input
                id="input-phone"
                v-model="form.phone.value"
                :state="form.phone.state"
                trim
                placeholder="Телефон"
                :disabled="true"
            />
          </b-form-group>
          <!-- /Поле Телефон -->

          <!-- Поле E-mail -->
          <p class="mb-2">
            E-mail
          </p>
          <b-form-group
              label-for="input-email"
              :invalid-feedback="form.email.invalidFeedback"
              :state="form.email.state"
          >
            <b-form-input
                id="input-email"
                v-model="form.email.value"
                :state="form.email.state"
                type="email"
                placeholder="E-mail"
                required
                trim
            />
          </b-form-group>
          <!-- /Поле E-mail -->

          <!-- Поле Название юридического лица -->
          <p class="mb-2">
            Название компании <span class="text-danger font-weight-bold">*</span>
          </p>
          <InputSuggestDropdown
              label-for="input-company-name"
              id="input-company-name"
              inputPlaceholder="Начните вводить юр. лицо (min 4 символа)"
              v-model="form.companyName.value"
              :state="form.companyName.state"
              :invalid-feedback="form.companyName.invalidFeedback"
              :isPending="!listLegalEntityIsLoading"
              :suggests="listLegalEntity"
              returnObjKey="value"
              @input="getListLegalEntity"
              @select="selectLegalEntity"
              v-slot="slotData"
          >
            {{ slotData.suggest.value }}<br/>
            <small>ИНН: {{ slotData.suggest.data.inn }}</small><br/>
            <small>{{ slotData.suggest.data.address.unrestricted_value }}</small>
          </InputSuggestDropdown>
          <!-- /Поле Название юридического лица -->

          <!-- Поле ИНН -->
          <InputSuggestDropdown
              label="ИНН"
              label-for="input-company-inn"
              id="input-company-inn"
              inputPlaceholder="Подставляется автоматически"
              inputType="number"
              v-model="form.companyInn.value"
              :state="form.companyInn.state"
              :invalid-feedback="form.companyInn.invalidFeedback"
              :isPending="!listInnIsLoading"
              :suggests="listInn"
              returnObjKey="value"
              @input="getListInn"
              @select="selectInn"
              v-slot="slotData"
              :disabled="true"
          >
            {{ slotData.suggest.value }}
          </InputSuggestDropdown>
          <!-- /Поле ИНН -->

          <!-- Поле Город -->
          <InputSuggestDropdown
              label="Город"
              label-for="input-company-city"
              id="input-company-city"
              inputPlaceholder="Подставляется автоматически"
              v-model="form.companyCity.value"
              :state="form.companyCity.state"
              :invalid-feedback="form.companyCity.invalidFeedback"
              :isPending="!listCityIsLoading"
              :suggests="listCity"
              returnObjKey="value"
              @input="getListCity"
              v-slot="slotData"
              :disabled="true"
          >
            {{ slotData.suggest.value }}<br/>
          </InputSuggestDropdown>
          <!-- /Поле Город -->

          <!-- Поле Должность -->
          <b-form-group
              label="Должность"
              label-for="input-position"
              :invalid-feedback="form.jobPosition.invalidFeedback"
              :state="form.jobPosition.state"
          >
            <b-form-input
                id="input-position"
                v-model="form.jobPosition.value"
                :state="form.jobPosition.state"
                trim
                placeholder="Например: Менеджер"
            />
          </b-form-group>
          <!-- /Поле Должность -->

          <!-- Поле Согласие -->
          <b-form-group
              label-for="input-privacyAccept"
              :invalid-feedback="form.privacyAccept.invalidFeedback"
              :state="form.privacyAccept.state"
          >
            <b-form-checkbox
                id="input-privacyAccept"
                v-model="form.privacyAccept.value"
                name="privacyAccept"
                value="accepted"
                :state="form.privacyAccept.state"
                unchecked-value="not_accepted"
            >
              {{ privacyPolicyText }} <span class="text-danger font-weight-bold">*</span>
            </b-form-checkbox>
          </b-form-group>
          <!-- /Поле Согласие -->

          <!-- Ошибка заполнения формы -->
          <b-alert
              show
              v-if="formErrorMessage"
              variant="danger"
          >
            {{ formErrorMessage }}
          </b-alert>
          <!-- /Ошибка заполнения формы -->

          <!-- Ошибка сервера -->
          <b-alert
              show
              v-if="serverErrorMessage"
              variant="danger"
          >
            {{ serverErrorMessage }}
          </b-alert>
          <!-- /Ошибка сервера -->

          <b-button
              type="submit"
              variant="primary"
              block
              :disabled="isFormButtonDisabled"
          >
            Сохранить
          </b-button>

          <!-- Обязательные поля -->
          <p class="mt-2">
            <span class="text-danger font-weight-bold">*</span> - обязательные поля
          </p>
          <!-- /Обязательные поля -->
        </b-form>
      </div>
    </b-overlay>
  </div>
</template>

<script>
import jsonwebtoken from 'jsonwebtoken';
import PageHeader from '../components/PageHeader.vue';
import InputSuggestDropdown from '../components/InputSuggestDropdown.vue';
import {
  getSeminar,
  checkJoiningToSeminar,
  joinToSeminar,
} from '../api/profi_club/seminars';
import {
  getCompaniesSuggestionByInn,
  getCompaniesSuggestionByEntityName,
  getCitiesSuggestion,
} from '../api/dadata';
import { getErrorTextFromErResp } from '../api/utils';
import { config } from '../config';

export default {
  name: 'JoinToSeminar',

  components: {
    PageHeader,
    InputSuggestDropdown,
  },

  props: {
    seminarId: {
      type: String,
      required: true,
    },
  },

  computed: {
    isFormButtonDisabled() {
      return !this.form.userName.value
          || !this.form.phone.value
          || !this.form.companyName.value
          || !this.form.privacyAccept.value
          || this.form.privacyAccept.value === 'not_accepted';
    },
  },

  data() {
    return {
      // Форма
      privacyPolicyText: 'Даю согласие на обработку персональных данных и '
          + 'соглашаюсь с политикой конфиденциальности',

      seminar: null,
      disbaledEmail: false,
      checkJoiningToSeminarStatus: false,

      isLoading: true,
      isSent: false,

      initPageServerErrorMessage: null,
      formErrorMessage: null,
      serverErrorMessage: null,

      form: {
        /* Любое поле содержит:
          - (value: str) значение;
          - (state: bool) состояние ошибки;
          - (invalidFeedback: str) текст ошибки;
        * * */
        userName: {
          value: '',
          state: null,
          invalidFeedback: null,
        },
        phone: {
          value: '',
          state: null,
          invalidFeedback: null,
        },
        email: {
          value: '',
          state: null,
          invalidFeedback: null,
        },
        companyInn: {
          value: '',
          state: null,
          invalidFeedback: null,
        },
        companyName: {
          value: '',
          state: null,
          invalidFeedback: null,
        },
        companyCity: {
          value: '',
          state: null,
          invalidFeedback: null,
        },
        privacyAccept: {
          value: 'not_accepted',
          state: null,
          invalidFeedback: null,
        },
        jobPosition: {
          value: '',
          state: null,
          invalidFeedback: null,
        },
      },

      // ИНН
      listInn: [],
      listInnIsLoading: false,

      // Юр. лица
      listLegalEntity: [],
      listLegalEntityIsLoading: false,

      // Города
      listCityIsLoading: false,
      listCity: [],
    };
  },

  methods: {
    async onSubmitForm(event) {
      event.preventDefault();
      this.formErrorMessage = null;
      this.form.privacyAccept.state = true;

      // Валидация
      if (this.form.privacyAccept.value !== 'accepted') {
        this.form.privacyAccept.state = false;
        this.form.privacyAccept.invalidFeedback = 'Обязательное поле!';
        return;
      }

      // Отправка на сервер
      this.isLoading = true;
      try {
        await joinToSeminar({
          seminarId: this.seminarId,
          userName: this.form.userName.value,
          phone: this.form.phone.value,
          email: this.form.email.value,
          companyInn: this.form.companyInn.value,
          companyCity: this.form.companyCity.value,
          companyName: this.form.companyName.value,
          jobPosition: this.form.jobPosition.value,
        });

        this.isLoading = false;
        this.isSent = true;
      } catch (e) {
        this.isLoading = false;
        this.serverErrorMessage = await getErrorTextFromErResp(e);
      }
    },
    onShowForm() {
      this.resetFormFields();
      this.isSent = false;
    },
    resetFormFields() {
      // TODO: Нужно провести рефакторинг сброса полей формы (через функцию)
      this.form.userName = {
        value: '',
        state: null,
        invalidFeedback: null,
      };
      this.form.phone = {
        value: '',
        state: null,
        invalidFeedback: null,
      };
      this.form.email = {
        value: '',
        state: null,
        invalidFeedback: null,
      };
      this.form.jobPosition = {
        value: '',
        state: null,
        invalidFeedback: null,
      };
      this.form.companyInn = {
        value: '',
        state: null,
        invalidFeedback: null,
      };
      this.form.companyName = {
        value: '',
        state: null,
        invalidFeedback: null,
      };
      this.form.companyCity = {
        value: '',
        state: null,
        invalidFeedback: null,
      };
      this.form.privacyAccept = {
        value: 'not_accepted',
        state: null,
        invalidFeedback: null,
      };
    },
    async getListInn(value) {
      if (!value || value === '' || value.length < 4 || this.listInnIsLoading) return;
      this.listInnIsLoading = true;
      try {
        this.listInn = await getCompaniesSuggestionByInn(value);
        this.listInnIsLoading = false;
      } catch (e) {
        this.listInnIsLoading = false;
        this.listInn = [];
      }
    },
    async getListLegalEntity(value) {
      if (!value || value === '' || value.length < 4 || this.listLegalEntityIsLoading) return;
      try {
        this.listLegalEntity = await getCompaniesSuggestionByEntityName(value);
        this.listLegalEntityIsLoading = false;
      } catch (e) {
        this.listLegalEntityIsLoading = false;
        this.listLegalEntity = [];
      }
    },
    async getListCity(value) {
      if (!value || value === '' || value.length < 4 || this.listCityIsLoading) return;
      try {
        this.listCity = await getCitiesSuggestion(value);
        this.listCityIsLoading = false;
      } catch (e) {
        this.listCityIsLoading = false;
        this.listCity = [];
      }
    },
    async selectInn(valueString, valueObject) {
      this.form.companyInn.value = valueObject.data.inn;
      this.form.companyName.value = valueObject.data.name.short_with_opf;
      this.form.companyCity.value = valueObject.data.address.data.city;
    },
    async selectLegalEntity(valueString, valueObject) {
      await this.selectInn(valueString, valueObject);
    },
  },

  async mounted() {
    // Получаем семинар
    try {
      this.seminar = await getSeminar(this.seminarId);

      this.isLoading = false;
    } catch (e) {
      this.isLoading = false;
      this.initPageServerErrorMessage = e.response.data.detail || 'Произошла ошибка (семинар)';
    }

    // Проверка на "уже присоединился"
    try {
      this.checkJoiningToSeminarStatus = await checkJoiningToSeminar(this.seminarId);

      this.isLoading = false;
    } catch (e) {
      this.isLoading = false;
      this.initPageServerErrorMessage = e.response.data.detail || 'Произошла ошибка (семинар)';
    }

    // Берем из JWT данные профиля
    try {
      const jwt = this.$store.getters['auth/jwt'];
      const profile = await jsonwebtoken.verify(jwt, config.publicKeyForJwt);

      this.form.userName.value = `${profile.firstName} ${profile.lastName}`;
      this.form.phone.value = profile.phoneNumber;

      if (profile.email) {
        this.disbaledEmail = true;
        this.form.email.value = profile.email;
      }
    } catch (err) {
      this.initPageServerErrorMessage = 'Произошла ошибка (профиль)';
    }

    // Форма
    this.formErrorMessage = null;
    this.serverErrorMessage = null;
    this.isLoading = false;
    this.isSent = false;
    // Обнуление списка ИНН
    this.listInnIsLoading = false;
    this.listInn = [];
    // Обнуление списка юридических лиц
    this.listLegalEntityIsLoading = false;
    this.listLegalEntity = [];
    // Обнуление списка городов
    this.listCityIsLoading = false;
    this.listCity = [];
  },
};
</script>

<style scoped lang="scss">
.join-to-seminar-view {
  max-width: 720px;
  margin: 0 auto;
  padding: 25px 10px 50px 10px;
  text-align: left;
  overflow: visible;
}
</style>
