import { axiosProfiClubApiWithCredentials } from '../index';

/* Получить инфу о семинаре */
export async function getSeminar(seminarId) {
  const urlSegment = `seminars/${seminarId}`;
  const response = await axiosProfiClubApiWithCredentials.get(urlSegment);
  return response.data ? response.data : null;
}
/* /Получить инфу о семинаре */

/* Получить инфу о семинаре */
export async function checkJoiningToSeminar(seminarId) {
  const urlSegment = `seminars/${seminarId}/check-joining`;
  const response = await axiosProfiClubApiWithCredentials.get(urlSegment);
  return response.data && response.data.status ? response.data.status : null;
}
/* /Получить инфу о семинаре */

/* Участвовать в семинаре */
export async function joinToSeminar({
  seminarId,
  userName,
  phone,
  email,
  companyCity,
  companyInn,
  companyName,
  jobPosition,
}) {
  // TODO
  const url = `seminars/${seminarId}/join`;
  const data = {
    userName,
    phone,
    email,
    companyCity,
    companyInn,
    companyName,
    jobPosition,
  };

  const response = await axiosProfiClubApiWithCredentials.post(url, data);

  return response.data ? response.data : null;
}
/* /Участвовать в семинаре */
